<template>
  <div ref="leftCenter" style="width: 100%; height: 100%" />
</template>

<script>
export default {
  data() {
    return {
      chart: null,
      chartData: [],
      dataForm: {
        householdCount: null, // 户用沼气池总量
        siteCount: null //沼气工程总量
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.leftCenter)
      this.$api.biogas.findProjectTotalCount().then(res => {
        if (res.code === 200) {
          this.dataForm = Object.assign({}, res.data)
          this.initChartData()
          this.initOption()
        }
      })
    },
    initOption() {
      let options = {
        color: ['#FF7469', '#2BD8FB'],
        tooltip: {
          // trigger: 'item',
          // formatter: '{b}: {c}',
          formatter(params) {
            let rsValue = params.value
            if (params.name === '沼气工程') {
              rsValue = params.value / 100
            }
            return params.name + ': ' + rsValue
          },
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,.6)',
          borderColor: 'rgba(147, 235, 248, .8)',
          textStyle: {
            color: '#FFF'
          }
        },
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            radius: '70%',
            center: ['48%', '53%'],
            startAngle: 250,
            label: {
              formatter(params) {
                let rsValue = params.value
                if (params.name === '沼气工程') {
                  rsValue = params.value / 100
                }
                return '{b|' + params.name + '}\n{c|' + rsValue + '}'
              },
              rich: {
                b: {
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  lineHeight: 26
                },
                c: {
                  color: '#31ABE3',
                  fontSize: 14
                },
                per: {
                  color: '#31ABE3',
                  fontSize: 14
                }
              }
            },
            labelLine: {
              length: 20, // 第一段线 长度
              length2: 30, // 第二段线 长度
              show: true,
              emphasis: {
                show: true
              }
            },
            data: this.chartData
          }
        ]
      }
      this.chart.setOption(options)
    },
    // 初始化设置饼图的数据
    initChartData() {
      let siteObj = {}
      siteObj.name = '沼气工程'
      siteObj.value = this.dataForm.siteCount * 100 // 由于沼气工程数量太小，饼图显示不好看，所以数据乘于100，后面显示再除掉
      siteObj.selected = true

      let householdObj = {}
      householdObj.name = '户用沼气池'
      householdObj.value = this.dataForm.householdCount
      this.chartData.push(siteObj)
      this.chartData.push(householdObj)
    }
  }
}
</script>
<style lang="scss" scoped></style>
