<template>
  <div ref="waterChart" style="width: 100%; height: 100%"></div>
</template>

<script>
import 'echarts-liquidfill' //在这里引入水球图
let chart = null
export default {
  name: 'WaterChart',
  props: {
    // 水波图标题
    title: {
      type: String,
      default: ''
    },
    // 当前需要显示计算的数量
    curCount: {
      type: Number,
      default: 0
    },
    // 所有总数量，计算显示图形比率时=curCount/allCount
    allCount: {
      type: Number,
      default: 1
    },
    // 水波从底部往上的颜色 底部颜色
    color1: {
      type: String,
      default: 'rgba(58, 71, 212, 0)'
    },
    // 水波从底部往上的颜色 中间颜色
    color2: {
      type: String,
      default: 'rgba(31, 222, 225, .2)'
    },
    // 水波从底部往上的颜色 顶部颜色
    color3: {
      type: String,
      default: 'rgba(31, 222, 225, 1)'
    }
  },
  data() {
    return {}
  },
  mounted() {
    /*this.$nextTick(() => {
      this.initChart()
    })*/
  },
  methods: {
    initChart() {
      chart = this.$echarts.init(this.$refs.waterChart)
      this.setOptions()
    },
    setOptions() {
      let value = (this.curCount / this.allCount).toFixed(2)
      let option = (option = {
        backgroundColor: 'rgba(0,0,0,0)', // 画布背景色设置为透明
        title: [
          {
            text: this.title,
            left: 'center', // 定位
            top: '90%',
            textStyle: {
              fontSize: 16,
              fontWeight: '400',
              color: '#5dc3ea', // 字体
              fontFamily: 'Microsoft YaHei', // 字体
              lineHeight: 16,
              textAlign: 'center'
            }
          }
        ],
        graphic: [
          {
            type: 'group',
            left: 'center',
            top: '30%',
            children: [
              {
                type: 'text',
                z: 100,
                left: '40',
                top: 'middle',
                style: {
                  fill: '#aab2fa',
                  text: '户数：' + this.curCount,
                  font: '16px Microsoft YaHei'
                }
              }
            ]
          }
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '80%', //水球图的半径
            center: ['50%', '45%'], // 水球图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
            data: [value], // data个数代表波浪数
            backgroundStyle: {
              color: {
                type: 'linear',
                x: 1,
                y: 0,
                x2: 0.5,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(68, 145, 253, 0)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(68, 145, 253, .25)'
                  },
                  {
                    offset: 0,
                    color: 'rgba(68, 145, 253, 1)'
                  }
                ],
                globalCoord: false
              }
            },
            // 图形上的文本标签
            label: {
              normal: {
                position: ['50%', '60%'], //此处调节显示的位置
                textStyle: {
                  fontSize: 28, // 中间显示百分比字体的大小
                  color: '#fff'
                }
              }
            },
            // 图形边框样式
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 5,
                // 边框颜色
                borderColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(69, 73, 240, 0)'
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(69, 73, 240, .25)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(69, 73, 240, 1)'
                    }
                  ],
                  globalCoord: false
                },
                shadowBlur: 10, // 边框的阴影范围 一旦设置了内外都有阴影
                shadowColor: '#000' // 边框的阴影颜色,'#134547'
              }
            },
            // 图形样式
            itemStyle: {
              // 水球显示的背景颜色
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: this.color1
                  },
                  {
                    offset: 0.5,
                    color: this.color2
                  },
                  {
                    offset: 0,
                    color: this.color3
                  }
                ],
                globalCoord: false
              }
            }
          }
        ]
      })
      chart.setOption(option)
    }
  }
}
</script>

<style lang="scss"></style>
