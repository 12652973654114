<template>
  <div ref="leftCenter" style="width: 100%; height: 100%" />
</template>

<script>
export default {
  name: 'LeftCenter',
  data() {
    return {
      chart: null,
      countData: {
        householdCount: 0,
        normalCount: 0,
        stopCount: 0,
        scrapCount: 0,
        pendingCount: 0
      },
      // 圆环图各环节的颜色
      colors: ['#56B557', '#33A1DB', '#ECA444', '#9966ff']
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.leftCenter)
      this.$api.biogas.findProjectVerCount().then(res => {
        if (res.code === 200) {
          this.countData = Object.assign({}, res.data)
          this.initOption()
        }
      })
    },
    initOption() {
      // 图表基本信息显示配置
      let piedata = {
        name: '2021年核验情况',
        type: 'pie',
        radius: ['40%', '60%'],
        // 饼块起始角度
        startAngle: 280,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
          borderColor: 'rgba(0,0,0,0)',
          borderWidth: 2
        },
        color: this.colors,
        // 圆环图各环节的名称和值(系列中各数据项的名称和值)
        data: [
          {
            value: this.countData.normalCount,
            name: '正常(间歇)使用',
            label: {
              shadowColor: this.colors[0]
            }
          },
          {
            value: this.countData.stopCount,
            name: '停用',
            label: {
              shadowColor: this.colors[1]
            }
          },
          {
            value: this.countData.scrapCount,
            name: '报废',
            label: {
              shadowColor: this.colors[2]
            }
          },
          {
            value: this.countData.pendingCount,
            name: '待查项目',
            label: {
              shadowColor: this.colors[3]
            }
          }
        ]
      }
      let option = {
        // 设置显示的标题
        title: {
          // zlevel: 0,
          text: [
            '{value|' + this.countData.householdCount + '}',
            '{name|总户数}'
          ].join('\n'), // 标题名称
          // 标题的位置
          top: '130',
          left: 'center',
          // 标题的样式
          textStyle: {
            rich: {
              value: {
                color: '#ffffff',
                fontSize: 24,
                fontWeight: 'bold',
                lineHeight: 20
              },
              name: {
                color: '#ffffff',
                lineHeight: 20
              }
            }
          }
        },
        // 设置鼠标移动到图表上时，弹出的提示框
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,.6)',
          borderColor: 'rgba(147, 235, 248, .8)',
          textStyle: {
            color: '#FFF'
          }
        },
        series: [
          {
            ...piedata,
            tooltip: { show: true },
            // 格式化数据标签
            label: {
              formatter: '{b|{b}}\n {per|{d}%}\n{c|{c}户}',
              //   position: "outside",
              rich: {
                b: {
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  lineHeight: 26
                },
                c: {
                  color: '#31ABE3',
                  fontSize: 14
                },
                per: {
                  color: '#31ABE3',
                  fontSize: 14
                }
              }
            },
            labelLine: {
              length: 10, // 第一段线 长度
              length2: 20, // 第二段线 长度
              show: true,
              emphasis: {
                show: true
              }
            }
          },
          {
            ...piedata,
            tooltip: { show: true },
            itemStyle: {},
            label: {
              backgroundColor: 'auto', //圆点颜色，auto：映射的系列色
              height: 0,
              width: 0,
              lineHeight: 0,
              borderRadius: 2.5,
              shadowBlur: 8,
              shadowColor: 'auto',
              padding: [2.5, -2.5, 2.5, -2.5]
            },
            labelLine: {
              length: 10, // 第一段线 长度
              length2: 20, // 第二段线 长度
              show: false
            }
          }
        ]
      }
      this.chart.setOption(option)
    }
  }
}
</script>
<style lang="scss" scoped></style>
