import { render, staticRenderFns } from "./LeftTop.vue?vue&type=template&id=0495d794&scoped=true&"
import script from "./LeftTop.vue?vue&type=script&lang=js&"
export * from "./LeftTop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0495d794",
  null
  
)

export default component.exports