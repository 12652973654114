<template>
  <div id="data-view">
    <dv-full-screen-container>
      <top-header title="农村沼气设施智慧管理平台" />
      <dv-border-box-13 class="main-container">
        <el-row>
          <el-col :span="6" class="chart-container">
            <dv-border-box-10 class="left-chart-1" :reverse="true">
              <title-wrap
                title="2021年沼气设施汇总"
                style="width: 100%; height: 100%"
              >
                <left-top />
              </title-wrap>
            </dv-border-box-10>
            <dv-border-box-10 class="left-chart-2" :reverse="true">
              <title-wrap
                title="2021年户用沼气核验情况"
                style="width: 100%; height: 100%"
              >
                <left-center />
              </title-wrap>
            </dv-border-box-10>
          </el-col>
          <el-col :span="12" class="center-container">
            <dv-border-box-10 :reverse="true">
              <title-wrap
                title="2021年户用沼气正常(间歇)使用分布"
                style="width: 100%; height: 100%"
              >
                <guangxi-map @mapClickMethod="centerMapClick" />
              </title-wrap>
            </dv-border-box-10>
          </el-col>
          <el-col :span="6" class="right-container">
            <dv-border-box-10 :reverse="true">
              <title-wrap
                title="2022年户用沼气巡查进度"
                style="width: 100%; height: 100%"
              >
                <Right-Chart ref="rightChart" :cityId="cityId" />
              </title-wrap>
            </dv-border-box-10>
          </el-col>
          <el-col :span="24" class="bottom-container">
            <dv-border-box-10 :reverse="true">
              <title-wrap
                title="2021年户用沼气安全隐患情况"
                style="width: 100%; height: 100%"
              >
                <BottomCharts />
              </title-wrap>
            </dv-border-box-10>
          </el-col>
        </el-row>
      </dv-border-box-13>
    </dv-full-screen-container>
  </div>
</template>

<script>
// 标题区组件
import TopHeader from '@/components/datav/TopHeader'
import TitleWrap from '@/components/datav/TitleWrap'
import LeftTop from '@/components/datav/LeftTop'
import RightChart from '@/components/datav/RightChart'
import BottomCharts from '@/components/datav/BottomCharts'
import GuangxiMap from '@/components/datav/GuangxiMap'
import LeftCenter from '@/components/datav/LeftCenter'
export default {
  name: 'HomeDatav',
  components: {
    TopHeader,
    TitleWrap,
    LeftTop,
    RightChart,
    // eslint-disable-next-line vue/no-unused-components
    BottomCharts,
    GuangxiMap,
    LeftCenter
  },
  data() {
    return {
      cityId: 0
    }
  },
  methods: {
    // 中间地图组件点击时调用
    centerMapClick(cityId) {
      this.cityId = cityId
    }
  }
}
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('../assets/img/datav/bg2.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
    // 容器内项目的排列方向
    // row:横向从左往右排列(默认) column:纵向从上往下排列 column-reverse:纵向从下往上排列
  }

  .main-container {
    height: calc(100% - 80px);
    .border-box-content {
      padding: 15px 10px 10px 15px;
      box-sizing: border-box;
      display: flex;
    }
  }

  .chart-container {
    height: calc(100% - 300px);
  }

  .left-chart-1 {
    height: 49%;
    margin-bottom: 10px;
  }

  .left-chart-2 {
    height: 49%;
  }

  .center-container {
    height: calc(100% - 300px);
    width: 48.5%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .right-container {
    height: calc(100% - 300px);
    margin-bottom: 10px;
  }

  .bottom-container {
    height: 28%;
    padding-right: 5px;
  }
}
</style>
