<template>
  <div class="title-between-wrap">
    <div class="title-between-left-container">
      <div
        class="title-between-left-list"
        v-for="(item, index) in left"
        :key="index"
      >
        <div class="title-between-left-defaultStyle">
          <div class="title-between-menu">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="title-between-center-container">
      <h3 class="title-between-center-text">{{ title }}</h3>
    </div>
    <div class="title-between-right-container">
      <div
        class="title-between-right-list"
        v-for="(item, index) in right"
        :key="index"
      >
        <div class="title-between-right-defaultStyle">
          <div class="title-between-menu">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      left: [
        {
          link: 'example1',
          name: ''
        },
        {
          link: 'example2',
          name: ''
        },
        {
          link: 'example3',
          name: ''
        },
        {
          link: 'example4',
          name: ''
        }
      ],
      right: [
        {
          link: 'example5',
          name: ''
        },
        {
          link: 'example6',
          name: ''
        },
        {
          link: 'example7',
          name: ''
        },
        {
          link: 'example8',
          name: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.title-between-wrap {
  background: url('../../assets/img/datav/titlecenter.png') no-repeat;
  //   background-size: 100% 100%;
  background-position: top center;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-between-left-container {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .title-between-left-list {
      width: 25%;
      height: 60px;
      a {
        width: 100%;
        text-decoration: none;
        height: 60px;
        display: inline-block;
      }
      .title-between-left-defaultStyle {
        background: url('../../assets/img/datav/title_between_left_default.png')
          no-repeat;
        background-position: center center;
        // background-size: 100% 100%;
      }
      .router-link-active {
        background: url('../../assets/img/datav/title_between_left_active.png')
          no-repeat;
        background-position: center center;
        // background-size: 100% 100%;
      }
      .title-between-menu {
        font-weight: bold;
        cursor: pointer;
        letter-spacing: 4px;
        text-align: center;
        background-image: -webkit-linear-gradient(top, #ffffff, #98b6d1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        // font-style: italic;
        width: 100%;
        line-height: 60px;
        height: 60px;
      }
    }
  }
  .title-between-center-container {
    // width: 28%;
    font-size: 24px;
    a {
      text-decoration: none;
    }
    .title-between-center-text {
      cursor: pointer;
      letter-spacing: 2px;
      text-align: center;
      background-image: -webkit-linear-gradient(top, #ffffff, #5fb2ff);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .title-between-left-container,
  .title-between-right-container {
    width: 36%;
  }
  .title-between-right-container {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .title-between-right-list {
      width: 25%;
      height: 60px;
      a {
        width: 100%;
        text-decoration: none;
        height: 60px;
        display: inline-block;
      }
      .title-between-right-defaultStyle {
        background: url('../../assets/img/datav/title_between_right_default.png')
          no-repeat;
        background-position: center center;
        // background-size: 100% 100%;
      }
      .router-link-active {
        background: url('../../assets/img/datav/title_between_right_active.png')
          no-repeat;
        background-position: center center;
        // background-size: 100% 100%;
      }
      .title-between-menu {
        font-weight: bold;
        cursor: pointer;
        letter-spacing: 4px;
        text-align: center;
        background-image: -webkit-linear-gradient(top, #ffffff, #98b6d1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        // font-style: italic;
        width: 100%;
        line-height: 60px;
        height: 60px;
      }
    }
  }
}
</style>
