<template>
  <div ref="rightChart" style="width: 100%; height: 100%"></div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
let _this = null // 定义一个全局能调用的this
export default {
  name: 'RightChart',
  props: {
    cityId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      chart: null,
      recheckData: [], //服务端返回已核验巡查数据
      charData: { xData: [], normalData: [], pendingData: [] }, //条形图要显示的数据
      checkCity: false, // 是否选中市级单位进行查询 false不选中，true 选中
      doCount: 1, // 已轮播多少次
      timer: null // 定时器
    }
  },
  mounted() {
    _this = this
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.rightChart)
      this.$api.biogas.findRecheckCount().then(res => {
        if (res.code === 200) {
          let objList = res.data
          // 组装地图数据信息
          objList.map(item => {
            this.recheckData.push({
              orgId: item.orgId, // 对应市级ID - cityID
              orgName: item.orgName, // 区、县名称
              normalCount: item.normalCount, // 正常间歇使用 2022年开始巡查的总量
              normalVerCount: item.normalVerCount, // 正常间歇使用的已进行核验的总量
              pendingCount: item.pendingCount, // 待查项目 2022年开始巡查的总量
              pendingVerCount: item.pendingVerCount // 待查项目已进行核验的总量
            })
          })
          this.initOption(1)
        }
      })
    },
    initOption(cityId) {
      this.initCharData(cityId)
      let option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.6)',
          borderColor: 'rgba(147, 235, 248, .8)',
          textStyle: {
            color: '#FFF'
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          top: '3%',
          data: ['正常(间歇)使用', '待查项目'],
          show: true,
          textStyle: {
            color: '#fff'
          }
        },
        show: true,
        textStyle: {
          color: '#00c7ff'
        },
        grid: {
          left: '1%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: 100,
          // 显示竖向分割线 show=true 显示
          splitLine: {
            show: true,
            lineStyle: {
              color: '#063374'
            }
          },
          axisTick: {
            show: false //不显示小的刻度线
          },
          axisLine: {
            show: true, //显示底部X轴
            //  改变x轴颜色
            lineStyle: {
              color: '#26D9FF'
            }
          },
          axisLabel: {
            //  改变底部x轴字体颜色和大小
            textStyle: {
              color: 'rgba(250,250,250,0.5)',
              fontSize: 16
            }
          }
        },
        yAxis: {
          type: 'category',
          inverse: true,
          data: this.charData.xData,
          splitLine: {
            show: false //不显示横向分割线
          },
          axisTick: {
            show: false //不显示小的刻度线
          },
          axisLine: {
            //  改变y轴颜色
            lineStyle: {
              color: '#26D9FF'
            }
          },
          // 左边Y轴字体颜色设置
          axisLabel: {
            show: true,
            interval: 0,
            width: 70,
            overflow: 'breakAll', // interval width overflow设置文字自动换行
            textStyle: {
              color: '#00c7ff'
            },
            fontSize: 16
          }
        },
        dataZoom: [
          {
            yAxisIndex: 0,
            show: false,
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 7 // 一次性展示8个。
          }
        ],
        series: [
          {
            name: '正常(间歇)使用',
            type: 'bar',
            barWidth: '25%', // 柱子宽度
            // barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0.5,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 1,
                      color: 'rgba(85,209,255,1)'
                    },
                    {
                      offset: 0,
                      color: 'rgba(45,130,255,1)'
                    }
                  ],
                  globalCoord: false
                },
                borderWidth: 0,
                barBorderRadius: [0, 30, 30, 0] //圆角大小
              }
            },
            data: this.charData.normalData.map(item => {
              return {
                value: item,
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: item > 81 ? 'insideRight' : 'right',
                  // 设置label的文字颜色
                  textStyle: {
                    //数值样式
                    color: 'rgba(250,250,250,0.6)',
                    fontSize: 14,
                    fontWeight: 600
                  },
                  // 格式化label文字
                  formatter: '{c}%'
                }
              }
            })
          },
          {
            name: '待查项目',
            type: 'bar',
            barWidth: '25%',
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0.5,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 1,
                      color: 'rgb(254,227,166)'
                    },
                    {
                      offset: 0,
                      color: 'rgba(255,172,52,1)'
                    }
                  ],
                  globalCoord: false
                },
                borderWidth: 0,
                barBorderRadius: [0, 30, 30, 0] //圆角大小
              }
            },
            data: this.charData.pendingData.map(item => {
              return {
                value: item,
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: item > 81 ? 'insideRight' : 'right',
                  // 设置label的文字颜色
                  textStyle: {
                    //数值样式
                    color: 'rgba(250,250,250,0.6)',
                    fontSize: 14,
                    fontWeight: 600
                  },
                  // 格式化label文字
                  formatter: '{c}%'
                }
              }
            })
          }
        ]
      }
      this.chart.clear() // 先清空
      this.chart.setOption(option)

      // 定时器
      this.timer = setInterval(function() {
        if (_this.doCount === 1) {
          option.dataZoom[0].endValue = 7
          option.dataZoom[0].startValue = 0
        }
        if (_this.cityId > 1 && _this.doCount === 20) {
          _this.cityId = 1
          return
        }
        // 每次向后滚动一个，最后一个从头开始。
        if (option.dataZoom[0].endValue === _this.charData.xData.length - 1) {
          option.dataZoom[0].endValue = 7
          option.dataZoom[0].startValue = 0
        } else {
          option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1
          option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1
        }
        _this.chart.setOption(option)
        _this.doCount++
      }, 3000)
    },
    // 设置条形图的数据信息
    initCharData(cityId) {
      this.charData = { xData: [], normalData: [], pendingData: [] }
      if (cityId > 1) {
        let index = 0
        this.recheckData.map(item => {
          if (item.orgId === cityId) {
            this.charData.xData[index] = item.orgName
            this.charData.normalData[index] = this.numFilter(
              item.normalVerCount,
              item.normalCount
            )
            this.charData.pendingData[index] = this.numFilter(
              item.pendingVerCount,
              item.pendingCount
            )
            index++
          }
        })
      } else {
        this.recheckData.map((item, index) => {
          this.charData.xData[index] = item.orgName
          this.charData.normalData[index] = this.numFilter(
            item.normalVerCount,
            item.normalCount
          )
          this.charData.pendingData[index] = this.numFilter(
            item.pendingVerCount,
            item.pendingCount
          )
        })
      }
    },
    numFilter(value1, value2) {
      let realVal = 0.0
      if (value2 > 0) {
        // 截取当前数据到小数点后1位
        realVal = parseFloat((value1 / value2) * 100).toFixed(1)
      }
      return Number(realVal)
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    cityId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val > 0) {
          this.doCount = 1
          // 销毁定时器，保证只有一个定时器运行
          clearInterval(this.timer)
          this.initOption(val)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
