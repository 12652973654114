<template>
  <div class="bottom-charts">
    <div class="bc-chart-item" v-for="(item, index) in wtData" :key="index">
      <water-chart
        ref="wtChart"
        :title="item.title"
        :allCount="item.allCount"
        :curCount="item.curCount"
        :color3="item.color3"
      />
    </div>
  </div>
</template>

<script>
import WaterChart from '@/components/datav/WaterChart'
export default {
  name: 'BottomCharts',
  components: {
    WaterChart
  },
  data() {
    return {
      wtData: []
    }
  },
  mounted() {
    this.initChartData()
  },
  methods: {
    initChartData() {
      this.$api.biogas.findSafeCount().then(res => {
        if (res.code === 200) {
          this.setData(res.data)
          this.$nextTick(() => {
            this.wtData.forEach((item, index) => {
              this.$refs.wtChart[index].initChart()
            })
          })
        }
      })
    },
    setData(obj) {
      this.wtData = [
        {
          title: '无隐患',
          allCount: obj.householdCount,
          curCount: obj.safe1,
          color3: 'rgba(31, 222, 225, 1)'
        },
        {
          title: '盖板、护栏等安全围挡缺失',
          allCount: obj.householdCount,
          curCount: obj.safe2,
          color3: 'rgba(229, 124, 0)'
        },
        {
          title: '管道安装不规范',
          allCount: obj.householdCount,
          curCount: obj.safe3,
          color3: 'rgb(101,0,52)'
        },
        {
          title: '管道、炉灶具漏气',
          allCount: obj.householdCount,
          curCount: obj.safe4,
          color3: 'rgb(101,202,52)'
        },
        {
          title: '沼气池周围堆放易燃物',
          allCount: obj.householdCount,
          curCount: obj.safe5,
          color3: 'rgba(255, 51, 85)'
        },
        {
          title: '设备老化',
          allCount: obj.householdCount,
          curCount: obj.safe6,
          color3: 'rgb(0,51,203)'
        },
        {
          title: '无警示标志',
          allCount: obj.householdCount,
          curCount: obj.safe7,
          color3: 'rgb(152,51,1)'
        },
        {
          title: '其他',
          allCount: obj.householdCount,
          curCount: obj.safe8,
          color3: 'rgb(152,51,254)'
        }
      ]
    }
  }
}
</script>

<style lang="less">
.bottom-charts {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  .bc-chart-item {
    width: 25%;
    height: 100%;
    padding-top: 20px;
    box-sizing: border-box;
  }
}
</style>
