<template>
  <!-- 定义echarts需要控制的dom元素 -->
  <div ref="mapChart" style="width: 100%; height: 100%;"></div>
</template>

<script>
import guangxi from '../../assets/guangxi.json'
export default {
  name: 'GuangxiMap',
  data: function() {
    return {
      options: {},
      mapCode: 'guangxi',
      mapChart: null, // map组件对象 信息
      mapData: [], //地图要显示的数据信息
      scatterData: [] // 地图上显示的雷达散点数据信息
    }
  },
  mounted() {
    // dom元素加载完成后执行
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      this.mapChart = this.$echarts.init(this.$refs.mapChart)
      this.$echarts.registerMap(this.mapCode, guangxi)
      this.$api.biogas.findMapCount().then(res => {
        if (res.code === 200) {
          this.initMapData(res.data)
          this.initOption()
          this.mapcLick()
        }
      })
    },
    // 初始化设置地图数据
    initMapData(objList) {
      // 组装地图数据信息
      objList.map(item => {
        //  { name: '南宁市', value: 84818 },
        this.mapData.push({
          name: item.orgName,
          value: item.normalCount,
          orgId: item.orgId
        })
      })
      // 从地图json文件中获取地图的坐标信息
      let cityCenter = {}
      let arr = guangxi.features
      arr.map(item => {
        cityCenter[item.properties.name] =
          item.properties.centroid || item.properties.center
      })
      // 组装地图上散点信息
      this.mapData.map(item => {
        if (cityCenter[item.name]) {
          this.scatterData.push({
            name: item.name,
            value: cityCenter[item.name].concat(item.value),
            orgId: item.orgId
          })
        }
      })
    },
    initOption() {
      let top = 68
      let zoom = 1.2
      let option = {
        backgroundColor: 'rgba(0,0,0,0)',
        tooltip: {
          show: false
        },
        legend: {
          show: false
        },
        visualMap: {
          left: 20,
          bottom: 20,
          pieces: [
            { gte: 90001, label: '90000户以上' }, // 不指定 max，表示 max 为无限大（Infinity）。
            { gte: 70001, lte: 90000, label: '70001-90000户' },
            { gte: 50001, lte: 70000, label: '50001-70000户' },
            { gte: 30001, lte: 50000, label: '30001-50000户' },
            { gte: 10001, lte: 30000, label: '10001-30000户' },
            { lte: 10000, label: '1-10000户' } // 不指定 min，表示 min 为无限大（-Infinity）。
          ],
          inRange: {
            // 渐变颜色，从小到大
            color: [
              '#c3d7df',
              '#5cb3cc',
              '#8abcd1',
              '#66a9c9',
              '#2f90b9',
              '#1781b5'
            ]
          },
          textStyle: {
            color: '#fff'
          }
        },
        geo: {
          map: 'guangxi', //地图范围数据，通过echarts.registerMap引入(在node_modules里)
          roam: false, // 鼠标平移或者缩放
          selectedMode: false, //是否允许选中多个区域
          zoom: zoom,
          top: top,
          aspectScale: 0.78,
          tooltip: {
            show: false
          },
          label: {
            show: false // 是否显示对应地名
          }, //地图中文字内容及样式控制
          itemStyle: {
            areaColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)'
          },
          emphasis: {
            disabled: true
          }
        },
        series: [
          {
            name: '广西地图',
            type: 'map',
            mapType: this.mapCode,
            aspectScale: 0.78,
            data: this.mapData,
            selectedMode: false, //是否允许选中多个区域
            zoom: zoom,
            geoIndex: 1,
            top: top,
            tooltip: {
              show: true,
              formatter: function(params) {
                if (params.data) {
                  return params.name + '：' + params.data['value']
                } else {
                  return params.name
                }
              },
              backgroundColor: 'rgba(0,0,0,.6)',
              borderColor: 'rgba(147, 235, 248, .8)',
              textStyle: {
                color: '#FFF'
              }
            },
            label: {
              show: false,
              color: '#000',
              // position: [-10, 0],
              formatter: function(val) {
                if (val.data !== undefined) {
                  return val.name.slice(0, 2)
                } else {
                  return ''
                }
              },
              rich: {},
              emphasis: { show: false }
            },
            itemStyle: {
              borderColor: 'rgba(147, 235, 248, .8)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }
                ],
                globalCoord: false // 缺为 false
              },
              shadowColor: 'rgba(128, 217, 248, .3)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 1
              }
            }
          },
          {
            data: this.scatterData,
            type: 'effectScatter',
            coordinateSystem: 'geo',
            // eslint-disable-next-line no-unused-vars
            symbolSize: function(val) {
              //点的大小，根据数据值来定
              return 4
              // return val[2] / 50;
            },
            legendHoverLink: true,
            showEffectOn: 'render', // 何时显示特效，'render' 绘制完成后显示，'emphasis'鼠标放上去后显示。
            rippleEffect: {
              // 涟漪特效相关配置
              scale: 6,
              color: 'rgba(255,255,255, 1)',
              brushType: 'fill' //波纹的绘制方式，可选 'stroke' 和 'fill'。
            },
            tooltip: {
              show: true,
              formatter: function(params) {
                if (params.data) {
                  return params.name + '：' + params.data['value'][2]
                } else {
                  return params.name
                }
              },
              backgroundColor: 'rgba(0,0,0,.6)',
              borderColor: 'rgba(147, 235, 248, .8)',
              textStyle: {
                color: '#FFF'
              }
            },
            label: {
              formatter: param => {
                return param.name.slice(0, 2)
              },
              fontSize: 11,
              offset: [0, 2],
              position: 'bottom',
              textBorderColor: '#fff',
              textShadowColor: '#000',
              textShadowBlur: 10,
              textBorderWidth: 0,
              color: '#FFF',
              show: true
            },
            itemStyle: {
              color: 'rgba(255,255,255,1)',
              borderColor: 'rgba(2255,255,255,2)',
              borderWidth: 4,
              shadowColor: '#000',
              shadowBlur: 10
            }
          }
        ]
      }
      this.mapChart.setOption(option)
    },
    // 地图上点击事件
    mapcLick() {
      this.mapChart.on('click', params => {
        let cityId = params.data.orgId
        this.$emit('mapClickMethod', cityId)
      })
    }
  }
}
</script>

<style scoped></style>
